<template>
  <div class="searchresultboxmobail">

    <!-- <span class="proposedflight_box">הטיסה המוצעת</span> -->
    <span class="proposedflight_box" v-if="isFcSupplied">{{$t("sabre.search-result.box.recommended")}}</span>

    <mobile-one-flight-item :data="data.legs[0]" :fcFlight="isFcSupplied"/>

    <mobile-one-price-item :totalPrice="totalPrice" :data="data" kind="one-way"/>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'mobile-result-box-round-trip',
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  components: {
    MobileOneFlightItem: () => import('./mobileBoxAtom/mobileOneFlightItem'),
    MobileOnePriceItem: () => import('./mobileBoxAtom/mobileOnePriceItem'),
  },
  computed: {
    ...mapGetters({
      itinGroups: 'GET_SABRE_FLIGHT_ITIN_GROUPS',
    }),
    totalPrice() {
      const { data } = this;
      return data.excerpt?.totalPrice || 0;
    },
    isFcSupplied() {
      const { itinGroups, data } = this;
      return itinGroups?.find((itin) => itin.id === data.itinGroupRef)?.fcSupplied;
    },
  },
};
</script>
